/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
 
import 'bootstrap';

// Definizione funzioni

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

				jQuery(document).ready( function() {

					jQuery(".cliccabile").click(function() {
						window.location = jQuery(this).find("a").attr("href");
						return false;
					});

					//jQuery('body').obfuscateEmails();
					
					
					// Ombra header allo scrollDown
					
					var lastScrollTop = 0;
					jQuery(window).scroll(function(event){
				   var st = $(this).scrollTop();
				   if (st > lastScrollTop){
					   jQuery('.menu-container').addClass("shadowed");
				   } else if(st == 0) {
					   jQuery('.menu-container').removeClass("shadowed");
				   }
				   lastScrollTop = st;
					});


/*
					jQuery('.cookie-message').cookieBar({
						closeButton : '.ap-close-button', hideOnClose: false, domain: 'studioadelaide.site'
					});
				  jQuery('.cookie-message').on('cookieBar-close', function() {
					  jQuery(this).slideUp();
				  });
*/

				});

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        
				jQuery(document).ready( function() {

					jQuery("#btn-gestionali").click(function(){
					  jQuery("#box-gestionali").slideToggle();
						jQuery("#btn-gestionali").toggleClass("open");
					}); 					

					jQuery("#btn-operativi").click(function(){
					  jQuery("#box-operativi").slideToggle();
						jQuery("#btn-operativi").toggleClass("open");
					}); 					

					jQuery("#btn-arricchitore").click(function(){
					  jQuery("#box-arricchitore").slideToggle();
						jQuery("#btn-arricchitore").toggleClass("open");
					}); 	
					
					// Scroll animato alle ancore
					
					function scrollToAnchor(aid){
				    var aTag = jQuery(aid);
				    var topPoint = aTag.offset().top - 95;
				    jQuery('html,body').animate({scrollTop: topPoint},'slow');
					}

					jQuery(".gogest").click(function() {
					  	scrollToAnchor('.vantaggi.gestionali');
					});

					jQuery(".gonutr").click(function() {
					  	scrollToAnchor('.vantaggi.nutrizionali');
					});
					
					jQuery(".goop").click(function() {
					  	scrollToAnchor('.vantaggi.operativi');
					});
					
					jQuery(".goarric").click(function() {
					  	scrollToAnchor('.vantaggi.arricchitore');
					});
					
					jQuery(".goform").click(function() {
					  	scrollToAnchor('.vantaggi.formati');
					});
					
					jQuery(".gocont").click(function() {
					  	scrollToAnchor('.contatti');
					});

/*
window.addEventListener("scroll", function (event) {
    var scroll = this.scrollY;
    console.log(scroll)
});
*/

const container = document.querySelector('#scroller')
container.scrollTop
container.scrollLeft
//console.log(container.scrollLeft)
container.addEventListener("scroll", function (event) {
    var scroll = container.scrollLeft;
    console.log(scroll)
});

														
					        
				});        
        
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
